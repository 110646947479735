import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, Dropdown, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'
import Logo7 from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import icon1 from '../../assets/img/icons/userOktag.svg'
import icon2 from '../../assets/img/icons/shieldLock.svg'
import icon3 from '../../assets/img/icons/pcCog.svg'
import icon4 from '../../assets/img/icons/userCog.svg'
import iconId from '../../assets/img/logos/icono_ID.svg'

import logo_framework_titulo from '../../assets/img/AVAP_ID_horizontal.png'
import cabecera from '../../assets/img/foto-AVAP ID-2.png'
import cabeceraMobile from '../../assets/img/foto_AVA_ID_mobile.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import vineta_CN from '../../assets/img/vineta_avap.png'
import gafico_cn_i from '../../assets/img/grafico_avap_i.png'

import { BrowserView, MobileView } from 'react-device-detect';


type DashProps = {
    sider: any
};



const NewLandingAVAPID = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };



    return (
        <div>
            <BrowserView>
                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }}></div>

                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }}>

                    <Navbar className='navbar' style={{ position: 'fixed', width: '100%' }}>
                        <Container className="">
                            <Navbar.Brand href="/framework" style={{}}>
                                <Image src={logo_framework} style={{ marginBottom: '0px', width: '317px' }} height="33px" width="317px" alt="Logo" />
                            </Navbar.Brand>
                            <Nav style={{ width: '80%' }}>
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu1(!menu1) }} style={{ marginRight: '10px', marginLeft: '10px' }}>Products <div hidden={!menu1} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu2(!menu2) }} style={{ marginRight: '10px', marginLeft: '10px' }}>Services <div hidden={!menu2} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                                <Nav.Link onClick={() => { history.push('/avapid'); cleanMenuAll() }} style={{ marginRight: '10px', marginLeft: '10px' }}>AVAP ID <div hidden={!menu3} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu4(!menu4) }} style={{ marginRight: '10px', marginLeft: '10px' }}>Resources<div hidden={!menu4} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu5(!menu5) }} style={{ marginRight: '10px', marginLeft: '10px' }}>Company  <div hidden={!menu5} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
                            </Nav>
                            <button
                                style={{ padding: '0px', width: '350px', height: '30px' }}
                                className='custom-button-green textBold'
                                onClick={() => { history.push('/login') }}
                            >
                                Sign in with your AVAP ID
                            </button>
                        </Container>

                        {menu1 &&
                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div>
                                <div className='fade-in-image' style={{
                                    marginTop: '374px',
                                    marginLeft: '10%',
                                    marginRight: 'auto',
                                    position: 'absolute',
                                    width: '80%',
                                    backgroundColor: 'white',
                                    borderBottomLeftRadius: '15px',
                                    borderBottomRightRadius: '15px',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                                }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Build Virtual APIs
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }} onClick={() => { history.push('/avap') }}>
                                                AVAP (Advanced Virtual API Programming)
                                            </div>
                                            <div onClick={() => { history.push('/vsc-extensions') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                AVAP API Extensions for Microsoft Visual Code
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }} onClick={() => { history.push('/devstudio') }}>
                                                AVAP Dev Studio
                                            </div>



                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Test & Deliver APIs
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div onClick={() => { history.push('/version-control') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                AVAP API Version Control
                                            </div>
                                            <div onClick={() => { history.push('/api-publisher') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                AVAP API Publisher
                                            </div>

                                        </div>


                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Run APIs
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>
                                            {/*}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Virtual Server
                                </div> */}
                                            {/*}    <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Community Edition
                                </div>*/}
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }} onClick={() => { history.push('/avs') }}>
                                                AVAP Virtual Server
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }} onClick={() => { history.push('/avap') }}>
                                                AVAP Interpreter & Language Server
                                            </div>
                                        </div>



                                    </div>

                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Power Up APIs
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>


                                            <div onClick={() => { history.push('/avap-marketplace') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                AVAP Marketplace
                                            </div>
                                            <div onClick={() => { history.push('/avap-connectors') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer',
                                                    marginBottom: '34px',
                                                }}

                                            >
                                                AVAP Connectors
                                            </div>
                                            {/*}    <div style={{
                paddingLeft:'20px',
                paddingTop:'10px',
                color:'#969696',
                marginBottom:'34px',
                cursor:'pointer'
            }}>
            AVAP Virtual Machine
        </div>*/}



                                        </div>
                                        {/*}
                    <div style={{
                        width:'33%'
                    }}>
                        <div className='landingFont3'
                            style={{
                                paddingLeft:'20px',
                                paddingTop:'30px'
                            }}
                        >
                            Extend APIs
                        </div>
                        <div style={{
                            height:'1px',
                            backgroundColor:'#d28e22',
                            marginLeft:'20px',
                            marginRight:'20px',
                                cursor:'pointer'
                        }}>
                        </div>
    
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                cursor:'pointer'
                            }}>
                            Plug Ins
                        </div>
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                marginBottom:'34px',
                                cursor:'pointer'
                            }}>
                            Connectors
                        </div>
    
    
                    </div>
    
    
                */}



                                    </div>


                                </div></>
                        }

                        {menu2 &&
                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div><div className='fade-in-image' style={{
                                marginTop: '215px',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                position: 'absolute',
                                width: '80%',
                                backgroundColor: 'white',
                                borderBottomLeftRadius: '15px',
                                borderBottomRightRadius: '15px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                            }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Professional
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => { history.push('/collaborative') }}
                                            >
                                                AVAP Collaborative Network
                                            </div>
                                            <div onClick={() => { history.push('/cloud') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer',
                                                    marginBottom: '34px'
                                                }}>
                                                AVAP Cloud Professional
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Monolith to Microservices
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Power OpenAI Applications
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer',
            marginBottom:'35px'
        }}>
        Mobile Application API Development
    </div>
*/}


                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Business
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div onClick={() => { history.push('/cloud') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                AVAP Cloud Business
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Secure and Govern APIs
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Gateway for Istio
    </div>
*/}
                                        </div>


                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Corporate
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div onClick={() => { history.push('/cloud') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                AVAP Cloud Enterprise
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Decentralized Load Balancing
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Zero Trust Security
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Observability
    </div>
    */}
                                        </div>

                                    </div>
                                </div></>
                        }

                        {menu3 &&

                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div><div className='fade-in-image' style={{
                                marginTop: '148px',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                position: 'absolute',
                                width: '80%',
                                backgroundColor: 'white',
                                borderBottomLeftRadius: '15px',
                                borderBottomRightRadius: '15px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                            }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {/*} Partner Ecosystem*/}
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                display: 'none',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>



                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {/*Find a Partner*/}
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                display: 'none',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>



                                        </div>


                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {/*Become a partner*/}
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                display: 'none',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>


                                        </div>
                                    </div>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {/*Partner Login*/}
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: 'transparent',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer',
                                                marginBottom: '35px'
                                            }}>
                                            </div>
                                        </div>
                                    </div>
                                </div></>
                        }

                        {menu4 &&
                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div><div className='fade-in-image' style={{
                                marginTop: '216px',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                position: 'absolute',
                                width: '80%',
                                backgroundColor: 'white',
                                borderBottomLeftRadius: '15px',
                                borderBottomRightRadius: '15px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                            }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Developer Support
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => { history.push('/default') }}
                                            >
                                                AVAP Developer Portal
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer',
                                                marginBottom: '34px'
                                            }}
                                                onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank') }}
                                            >
                                                AVAP Communities
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Case Studies
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Blog
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Content Library
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Learning Center
    </div>

*/}

                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Knowledge Center
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US') }}
                                            >
                                                AVAP Alexandria
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => { window.open('http://blog.avapframework.com') }}
                                            >
                                                AVAP Blog
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Mesh
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP AI Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Insomnia
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Plugin Hub
    </div>
*/}
                                        </div>
                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                API Products
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>


                                            <div onClick={() => { history.push('/avap-marketplace') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                AVAP Marketplace
                                            </div>
                                        </div>
                                        {/*}
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Community
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                About Us
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Join the Community
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Livestreams & Events
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Champions
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Discuss
            </div>
        </div>
    
        </div>
    
        <div style={{
            width:'100%',
            display:'flex'
            }}>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Developer
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Contributor Program
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer',
                    marginBottom:'34px',
                }}>
                GitHub
            </div>
    
        </div>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px'
                }}
            >
                Academy
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Certification
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    marginBottom:'34px',
                    cursor:'pointer'
                }}>
                Education
            </div>
        </div> */}
                                    </div>

                                </div></>
                        }
                        {menu5 &&
                            <><div style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                marginTop: '52px',
                                position: 'absolute',
                                height: '2px',
                                backgroundColor: '#ebebeb'
                            }}></div><div className='fade-in-image' style={{
                                marginTop: '246px',
                                marginLeft: '10%',
                                marginRight: 'auto',
                                position: 'absolute',
                                width: '80%',
                                backgroundColor: 'white',
                                borderBottomLeftRadius: '15px',
                                borderBottomRightRadius: '15px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                            }}>


                                    <div style={{
                                        width: '100%',
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: '33%',
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Company
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer',
                                            }}>
                                            </div>

                                            <div onClick={() => { history.push('/about') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                About Us
                                            </div>
                                            <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer',
                                                marginBottom: '34px'
                                            }} onClick={() => { window.open('mailto: info@101obex.com', '_self') }}>
                                                Contact Us
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Press Room
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Investors
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Contact Us
    </div>
*/}

                                        </div>

                                        <div style={{
                                            width: '33%'
                                        }}>
                                            <div className='landingFont3'
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '30px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Legal
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                backgroundColor: '#d28e22',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}>
                                            </div>

                                            <div onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                Terms & Conditions AVAP Collaborative Networks
                                            </div>
                                            <div onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-cloud-professional-business-terms-conditions-MCOYQYTA5VQNDZDAS5UGXSU3GEV4?language=en_US') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer'
                                                }}>
                                                Terms & Conditions AVAP Cloud
                                            </div>
                                            <div onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US') }}
                                                style={{
                                                    paddingLeft: '20px',
                                                    paddingTop: '10px',
                                                    color: '#969696',
                                                    cursor: 'pointer',
                                                    marginBottom: '34px'
                                                }}>
                                                Privacy Policy
                                            </div>
                                            {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Documentation
    </div>
    

</div>


<div style={{
    width:'33%'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px',
            cursor:'pointer'
        }}
    >
        Events
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Summit by AVAP
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Upcomming Events
    </div>
    
</div>

</div>

<div style={{
    width:'100%',
    display:'flex'
    }}>

<div style={{
    width:'33%'
}}>
    

</div>

<div style={{
    width:'33%'
}}>

</div>

<div style={{
    width:'33%',
    marginTop:'-100px',
    marginBottom:'35px'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px'
        }}
    >
        Security
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Trust and Compilance
    </div>
    
    */}

                                        </div>

                                    </div>
                                </div></>
                        }

                    </Navbar>



                    <div onClick={() => { cleanMenuAll() }}>

                        <div onClick={() => { cleanMenuAll() }} className="d-flex flex-column align-items-center">


                            <div className='d-flex flex-column  align-items-center'>

                                <Image src={cabecera} style={{ marginTop: '40px' }} width="" alt="Logo" />
                                <Image src={logo_framework_titulo} style={{
                                    marginTop: '80px',
                                    position: 'relative',
                                    width: '500px'
                                }} width="" alt="Logo" />
                                {/* 
                                <div
                                    className='landingFont0 txt-light'
                                    style={{
                                        marginTop: '80px',
                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px',
                                        fontFamily: 'Noto Sans", sans-serif'
                                    }}
                                >Your account for AVAP Framework  </div>


                                <div
                                    className='landingFont0 txt-light'
                                    style={{
                                        marginTop: '0px',
                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px',
                                        fontFamily: 'Noto Sans", sans-serif'
                                    }}
                                >
                                    Your AVAP ID will give you <span className=' textBold'>access to AVAP</span>
                                </div>
                                <div className='landingFont0 textBold' style={{ color: 'black', fontFamily: 'Noto Sans", sans-serif' }}>
                                    products and services.
                                </div>
                                 */}


                                <div
                                    className='txt-28'
                                    style={{
                                        marginTop: '80px',
                                        position: 'relative',
                                        color: 'black',
                                        marginLeft: '25%',
                                        marginRight: '25%',
                                        textAlign: 'center'
                                    }}
                                >
                                    Your AVAP ID is the key to unlocking the full potential of the AVAP Framework. With a single account, you can seamlessly access and manage all AVAP products and services.
                                    Whether you're developing APIs, managing cloud infrastructure, or collaborating with others, your AVAP ID ensures that all your tools are connected and ready to support your work.
                                </div>

                            </div>
                        </div>

                        <div style={{
                            backgroundColor: '#ffffff',
                            height: '200px',
                            textAlign: 'center',
                            paddingTop: '30px'
                        }}>
                            {/*}
                <div className='landingFont2 textBold'>
                Ready to transform API development?
                </div>
                <div className='landingFont3b textBold'>
                Register at AVAP ID
                </div>

            */}
                            <button
                                onClick={() => { history.push('/Register') }}
                                className='greenbutton my-6'
                            >
                                Start with AVAP ID
                            </button>

                        </div>

                        <section className='w-100 bg-division mt-5'>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center my-7 landingFont0 txt-light'>What is AVAP ID?</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center txt-28  mb-7'>
                                            Your AVAP ID is your personal account that grants access to the full suite of AVAP Framework products and services,
                                            such as AVAP Cloud, AVAP Interpreter & Language Server, AVAP Gateway Advanced, and AVAP Collaborative Network.
                                            It securely stores your contact information, sign-in credentials, payment methods, and security details.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center my-7 landingFont0 txt-light'>When do I use my AVAP ID?</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center txt-28  mb-7'>
                                            You’ll use your AVAP ID whenever you set up a new device, make a purchase, or access any AVAP service.
                                            Signing in with the same AVAP ID ensures that all your AVAP products and services work together seamlessly,
                                            providing you with access to your content and projects across all your devices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='w-100'>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center mt-7 mb-4 txt-60 txt-light'>Top <span className=' textBold'>Benefits</span></p>
                                    </div>
                                    <div className="col-md-12">
                                        <p className='text-center txt-20'>Here are the top three benefits of your AVAP ID</p>
                                    </div>
                                </div>
                                <div className="row mt-6">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon1} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0'>Single Access Point</p>
                                        <p className='txt-20'>
                                            Use your AVAP ID for seamless access to all AVAP Framework, ensuring an integrated and efficient experience across products and services.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon2} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0'>Enhanced Security</p>
                                        <p className='txt-20'>
                                            Your AVAP ID is protected by advanced security features, keeping your personal data, projects, and payment information safe.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon3} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0'>Cross-Platform Syncing</p>
                                        <p className='txt-20'>
                                            Keep your work, settings, and content synchronized across all devices and services connected to your AVAP account.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3 pb-7">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon4} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0'>Streamlined Management:</p>
                                        <p className='txt-20'>
                                        Manage all your AVAP products, purchases, and services from one central account, simplifying your user experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id='lastSection' className='container'>
                            <div className="row">
                                <div className="col-md-12">
                                    <Image src={iconId} className='icon-65  d-block icon-fix' alt="Logo ID" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='txt-20 text-center'>
                                        With AVAP ID, you gain a secure, efficient, and unified way to
                                        <br /> manage your AVAP Framework products and services, making your
                                        <br /> API development journey faster and easier.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button onClick={() => { history.push('/Register') }}
                                        className='greenbutton d-block mb-fix-136'
                                        style={{ marginTop: '20px' }}
                                    >
                                        Create my AVAP ID
                                    </button>

                                </div>
                            </div>
                        </section>




                        <div>
                            <FooterMenuAccess green={true} />
                        </div>

                    </div>
                </div>

            </BrowserView>

            <MobileView>
                <Navbar id='frameworkNav' collapseOnSelect expand="lg" bg="light" variant="light" className='fixed-top' style={{ width: '100%' }}>
                    <Container>
                        <Navbar.Brand href="/framework">
                            <Image src={logo_framework} style={{ marginTop: '-10px', paddingLeft: '15px' }} alt="Logo" className="mb-1" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='mr-2' >
                            <div className='hamburguer' style={{ marginTop: '-10px' }}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Navbar.Toggle>
                        <Navbar.Collapse style={{ backgroundColor: 'white', marginTop: '-13px' }} id="responsive-navbar-nav" className="w-100 justify-content-between align-items-center">
                            <div className="d-flex flex-wrap">
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu1(!menu1); }} className='hideLink'>
                                    Products
                                    <div hidden={!menu1} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Products" id="DropdownProducts" className='showLink'>

                                    <div className="section-header">Build Virtual APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP (Advanced Virtual API Programming)
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
                                        AVAP API Extensions for Microsoft Visual Code
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
                                        AVAP Dev Studio
                                    </div>


                                    <NavDropdown.Divider />
                                    <div className="section-header">Test & Deliver APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/version-control'); }}>
                                        AVAP API Version Control
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
                                        AVAP API Publisher
                                    </div>


                                    <NavDropdown.Divider />
                                    <div className="section-header">Run APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avs'); }}>
                                        AVAP Virtual Server
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP Interpreter & Language Server
                                    </div>

                                </NavDropdown>



                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu2(!menu2); }} className='hideLink'>
                                    Services
                                    <div hidden={!menu2} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Services" id="DropdownProducts" className='showLink'>
                                    <div className="section-header">Professional</div>
                                    <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
                                        AVAP Collaborative Network
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Professional
                                    </div>
                                    <NavDropdown.Divider />
                                    <div className="section-header">Business</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Business
                                    </div>


                                    <NavDropdown.Divider />
                                    <div className="section-header">Corporate</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Enterprise
                                    </div>

                                </NavDropdown>






                                <Nav.Link onClick={() => { history.push('/avapid'); cleanMenuAll(); }}>
                                    AVAP ID
                                    <div hidden={!menu3} className="menu-highlight"></div>
                                </Nav.Link>

                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu4(!menu4); }} className='hideLink'>
                                    Resources
                                    <div hidden={!menu4} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Resources" id="DropdownProducts" className='showLink'>
                                    <div className="section-header">Developer Support</div>
                                    {/*} <div className="section-content" onClick={() => { history.push('/default'); }}>
                                        AVAP Developer Portal
                                    </div> */}
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
                                        AVAP Communities
                                    </div>
                                    <NavDropdown.Divider />
                                    <div className="section-header">Knowledge Center</div>
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
                                        AVAP Alexandria
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
                                        AVAP Blog
                                    </div>
                                    <NavDropdown.Divider />
                                    <div className="section-header">API Products</div>
                                    {/*<div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                                        AVAP Marketplace
                                    </div>*/}
                                </NavDropdown>


                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu5(!menu5); }} className='hideLink'>
                                    Company
                                    <div hidden={!menu5} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Company" id="DropdownProducts" className='showLink'>
                                    <div className="section-content" onClick={() => { history.push('/about'); }}>
                                        About Us
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
                                        Contact Us
                                    </div>
                                </NavDropdown>
                            </div>
                            {/*<button
                                className="greenbutton"
                                onClick={() => { history.push('/login'); }}
                            >
                                Sign in with your AVAP ID
                            </button>*/}
                        </Navbar.Collapse>
                    </Container>

                    {menu1 && (
                        <>

                            <div className="menu-dropdown ">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Build Virtual APIs</div>
                                        <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                            AVAP (Advanced Virtual API Programming)
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
                                            AVAP API Extensions for Microsoft Visual Code
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
                                            AVAP Dev Studio
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Test & Deliver APIs</div>
                                        <div className="section-content" onClick={() => { history.push('/version-control'); }}>
                                            AVAP API Version Control
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
                                            AVAP API Publisher
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Run APIs</div>
                                        <div className="section-content" onClick={() => { history.push('/avs'); }}>
                                            AVAP Virtual Server
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                            AVAP Interpreter & Language Server
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </>
                    )}

                    {menu2 && (
                        <>
                            <div className="menu-dropdown">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Professional</div>
                                        <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
                                            AVAP Collaborative Network
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                            AVAP Cloud Professional
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Business</div>
                                        <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                            AVAP Cloud Business
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Corporate</div>
                                        <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                            AVAP Cloud Enterprise
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </>
                    )}

                    {menu3 && (
                        <>
                            <div className="menu-dropdown">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Partner Ecosystem</div>
                                        <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
                                            Find a Partner
                                        </div>
                                        <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
                                            Become a Partner
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {menu4 && (
                        <>
                            <div className="menu-dropdown">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Developer Support</div>
                                        {/*<div className="section-content" onClick={() => { history.push('/default'); }}>
                                        AVAP Developer Portal
                                    </div>*/}
                                        <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
                                            AVAP Communities
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Knowledge Center</div>
                                        <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
                                            AVAP Alexandria
                                        </div>
                                        <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
                                            AVAP Blog
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">API Products</div>
                                        {/* <div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                                        AVAP Marketplace
                                    </div>*/}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {menu5 && (
                        <>
                            <div className="menu-dropdown">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Company</div>
                                        <div className="section-content" onClick={() => { history.push('/about'); }}>
                                            About Us
                                        </div>
                                        <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
                                            Contact Us
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                </Navbar>




                {/* <Disclaimer /> */}

                <div id='mobileCloud' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabeceraMobile})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='logos' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    Your account for AVAP Framework. Your AVAP ID will give you
                                    <span className='textBold'>
                                        &nbsp;access to AVAP products and services.
                                    </span>
                                </h1>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5'>
                                    Your AVAP ID is the key to unlocking the full potential of the AVAP Framework.
                                    With a single account, you can seamlessly access and manage all AVAP products and services. Whether you're developing APIs,
                                    managing cloud infrastructure, or collaborating with others, your AVAP ID ensures that all your tools are connected and ready to support your work.

                                </p>
                                {/*}  <button onClick={() => { history.push('/Register') }} className='bluebutton  d-table m-auto mb-6 '> Try it</button> */}
                            </div>
                        </div>

                    </section>

                    <section className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center hero-title mt-6'>What is AVAP ID?</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center mb-5 mt-2'>
                                        Your AVAP ID is your personal account that grants access to the full suite of AVAP Framework products and services, such as AVAP Cloud, AVAP Interpreter & Language Server, AVAP Gateway Advanced, and AVAP Collaborative Network.
                                        It securely stores your contact information, sign-in credentials, payment methods, and security details.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center hero-title mt-4'>When do I use my AVAP ID?</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center mb-5 mt-2'>
                                        You’ll use your AVAP ID whenever you set up a new device, make a purchase, or access any AVAP service.
                                        Signing in with the same AVAP ID ensures that all your AVAP products and services work together seamlessly,
                                        providing you with access to your content and projects across all your devices.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='w-100'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center mt-7 mb-4 txt-60 txt-light'>Top <span className=' textBold'>Benefits</span></p>
                                </div>
                                <div className="col-md-12">
                                    <p className='text-center txt-20 text-center'>Here are the top three benefits of your AVAP ID</p>
                                </div>
                            </div>
                            <div className="row mt-6">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon1} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Single Access Point</p>
                                    <p className='txt-20 text-center'>
                                    Use your AVAP ID for seamless access to all AVAP Framework, ensuring an integrated and efficient experience across products and services.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon2} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Enhanced Security</p>
                                    <p className='txt-20 text-center'>
                                    Your AVAP ID is protected by advanced security features, keeping your personal data, projects, and payment information safe.
                                        </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon3} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Cross-Platform Syncing</p>
                                    <p className='txt-20 text-center'>
                                    Keep your work, settings, and content synchronized across all devices and services connected to your AVAP account.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3 pb-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon4} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Streamlined Management:</p>
                                    <p className='txt-20 text-center'>
                                        Manage all your AVAP products, purchases, and services from one central account, simplifying your user experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id='lastSection' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={iconId} className='icon-65  d-block icon-fix' alt="Logo ID" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='txt-20 text-center mb-7'>
                                    With AVAP ID, you gain a secure, efficient, and unified way to
                                    <br /> manage your AVAP Framework products and services, making your
                                    <br /> API development journey faster and easier.
                                </p>
                            </div>
                        </div>

                        {/* <div className="row">
                                <div className="col-md-12">
                                    <button onClick={() => { history.push('/Register') }}
                                        className='greenbutton d-block mb-fix-136'
                                        style={{ marginTop: '20px' }}
                                    >
                                        Create my AVAP ID
                                    </button>

                                </div>
                            </div> */}
                    </section>




                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>
        </div>
    )
}

export default NewLandingAVAPID;